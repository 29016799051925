/* eslint-disable @typescript-eslint/naming-convention */
import PublicEnv from 'shared/utils/public-env';

export enum EnrollmentSteps {
  connectBank = 'CONNECT_BANK',
  completeEnrollment = 'COMPLETE_ENROLLMENT',
  emailForm = 'EMAIL_FORM',
  forgotPasswordForm = 'FORGOT_PASSWORD_FORM',
  learnMore = 'LEARN_MORE',
  passwordForm = 'PASSWORD_FORM',
  signUpForm = 'SIGN_UP_FORM',
  terms = 'TERMS',
  bankAccountSelector = 'ACCOUNT_SELECTOR',
  bankAccountsLoading = 'BANK_ACCOUNTS_LOADING',
  tosLoading = 'TOS_LOADING',
  unsupportedAccount = 'UNSUPPORTED_ACCOUNT',
  alreadySignedUp = 'ALREADY_SIGNED_UP',
  success = 'SUCCESS',
  completeAccount = 'COMPLETE_ACCOUNT',
}

export enum StatusCodes {
  denied = 'DENIED',
  challenged = 'CHALLENGED',
  failed = 'FAILED',
  locked = 'LOCKED',
  impeded = 'IMPEDED',
  discontinued = 'DISCONTINUED',
  closed = 'CLOSED',
}

export enum PaymentStatus {
  failed = 'failed',
  succeeded = 'succeeded',
  requiresCapture = 'requires_capture',
}

export enum ConnectionStatus {
  revoked = 'CONNECTION_STATUS_REVOKED',
  aggregatorConnectionError = 'AGGREGATOR_CONNECTION_ERROR',
}

export const editBankAccount = 'editBankAccount';
export const relinkBankAccount = 'relinkBankAccount';

export const connectingBankSteps = [
  `Syncing to your bank`,
  `Securing account information`,
  `Processing Account Information`,
  `Almost done`,
  `Finishing up`,
];
export const tosSteps = [`Syncing your account`, `Processing account information`, `Almost done`, `Finishing up`];

export enum StepNumberActions {
  decrement = 'decrement',
  increment = 'increment',
}

export type MXRegistrationPayloadProps = {
  mxWidgetUrl: string;
  billingAddressId: string;
};

export type BankAccount = {
  accountAlias: string;
  accountGuid: string;
  availableBalance: number;
  bankName: string;
  lastDigits: string;
};

export type ConnectedBankAccountDetails = {
  ownerName: string;
  bankName: string;
  lastDigits: number | string;
  id: string;
  accountName?: string;
};

export const instoreCartPollingInterval = 5000;

export const AGGREGATORS = {
  PLAID: `plaid`,
  MX: `mx`,
};

export const ERROR_TYPES = {
  DUTCHIE_PAY: `dutchiePay`,
  CREDIT: `credit`,
};

export const PLAID_STORAGE_KEY = 'plaidToken';
export enum PlaidLinkCustomizationNames {
  EMBEDDED_SEARCH_INSTORE = 'embedded_search_instore',
  EMBEDDED_SEARCH_ONLINE = 'embedded_search_online',
  INCENTIVES = 'incentive_applied',
  DEFAULT = 'default',
}

export const DUTCHIE_PAY_QUERY_PARMAS_KEY = 'dutchiePayQueryParams';

export const PLAID_OAUTH_PATH = 'oauth_state_id';

export const REDIRECT_URL = `${PublicEnv.consumerUrl}/pay/signup`;
export const PLAID_TERMS_URL = `https://business.dutchie.com/legal/digital-money-management-user-terms-plaid`;
export const MONEY_MGMT_TERMS_URL = `https://try.dutchie.com/digital-money-management-user-terms/`;
export const SIGNATURE_TERMS_URL = `https://try.dutchie.com/electronic-signature-agreement/`;
export const PBB_INCENTIVES_TERMS_URL = `https://business.dutchie.com/legal/dutchie-pay-by-bank-sign-up-bonus-terms`;

export const CHECKOUT_PATH = `checkout`;

export const ACCOUNT_SETTINGS_PATH = `account/bank-account`;

export const INSTORE_CART_PATH = `/pay/instore`;

export const DUTCHIE_PAY = 'dutchiePay';
export const RETHINK_PAY = 'rethinkPay';

export const PaymentType = {
  PAYMENT_TYPE_RETHINK: 'PAYMENT_TYPE_RETHINK',
};

export const RETHINK_PAY_TIMEOUT = 20000;
