import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { isRec } from 'shared/helpers/dispensaries';
import checkoutErrors from 'shared/core/fieldValidationErrorMessages';
import onClickSubmit from 'shared/checkout/actions/on-click-submit';
import { ModalContainer, ModalExitIcon, Button } from 'components/core';
import useStores from 'shared/hooks/use-stores';
import useBackwardsCompatibility from 'hooks/use-backwards-compatibility';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import useCart from 'shared/hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useTranslation from 'hooks/use-translation';
import useSwitchMenuType from 'hooks/use-switch-menu-type';
import { AmplitudeCategory, AmplitudeEvents, getCartEventProperties, useAmplitude } from 'shared/hooks/use-amplitude';
import createOrderV2 from 'checkout/components/confirmation/create-order.gql';
import { tracker, useViewedCheckoutMonitor } from 'src/utils/analytics';
import { useDutchiePay } from 'src/payments/hooks';
import { CheckoutProvider, contextStore } from 'checkout/context';
import { KioskOrderSuccessModal } from 'src/payments/modals/kiosk-order-succes';
import { KioskQrCode } from 'src/payments/modals/kiosk-qr-code/kiosk-qr-code';
import PaymentOptions from './payment-options';
import OrderInformation from './order-information';
import DirectOrders from './direct-orders';
import OrderItems from './order-items';
import useKioskTerminals from './use-kiosk-terminals';

const KioskCheckoutModal = forwardRef((props, ref) => {
  const { containerProps, onClose, dispensary: dispensaryFromProps } = props;
  useViewedCheckoutMonitor();
  const { dispensary = dispensaryFromProps } = useDispensary();
  const { menuType } = useCart();
  const useAnonymousKioskCheckout = dispensary?.orderTypesConfigV2.kiosk?.anonymousCheckout && menuType === `rec`;
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      birth: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      notes: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [isAnonymousCheckout, setIsAnonymousCheckout] = useState(false);
  const { Cart, UI } = useStores();
  const switchMenuType = useSwitchMenuType();
  const amplitude = useAmplitude();

  const kioskTerminals = useKioskTerminals(dispensary);
  const isCartEmpty = useObserver(() => Cart.isEmpty);

  const showDutchiePayQrCodeInKiosk = useObserver(() => UI.showDutchiePayQrCodeInKiosk);
  const showDutchiePayKioskOrderSuccess = useObserver(() => UI.showDutchiePayKioskOrderSuccess);

  const checkoutToken = Cart.order?.checkoutToken;
  const { isEnrolledDutchiePay } = useDutchiePay();
  const isDutchiePayEnabledForDispo = Cart.order?.dispensary?.paymentTypesAccepted.dutchiePay;

  // don't allow anonymous checkout if the menu type is med ENG-52870
  useEffect(() => {
    if (menuType === 'med') {
      setIsAnonymousCheckout(false);
    }
  }, [menuType]);

  // close the modal if the cart is empty
  useEffect(() => {
    if (isCartEmpty) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartEmpty]);

  // always default to pickup, tell the cart this is a kiosk order
  Cart.setOrderType(`pickup`);
  Cart.isKioskOrder = true;

  // setup checkout context and reverse compat mode in the stores
  const { User, apolloClient } = useBackwardsCompatibility();

  useEffect(() => {
    if (User.isLoggedIn) {
      User.logout();
    }
  }, [User]);

  contextStore.showErnie = UI.showErnie;
  contextStore.variant = UI.variant;
  contextStore.openOrderTypeModal = UI.openOrderTypeModal;

  function afterSubmit(data) {
    if (data?.createOrderV2?.valid) {
      // clear order assigns the temporary estimate for the success modal
      const { order } = data.createOrderV2;
      const values = formMethods.getValues();
      const { email } = values;

      if (!order.guestCustomer?.email && !!email) {
        order.guestCustomer.email = email;
      }

      tracker.placedOrder({ checkoutToken, order, isDutchiePayEnabledForDispo, isEnrolledDutchiePay });
      if (!UI.showDutchiePayQrCodeInKiosk) {
        onClose();
        Cart.clearOrder();
      }
      Cart.setAnonymousOrderId(order.orderId);
      Cart.setOrderType(`pickup`);
      formMethods.reset();

      if (isRec(dispensary)) {
        switchMenuType(`rec`);
      }

      // pop up success modal once we're totally done
      // it'll nav behind the modal
      if (!UI.showDutchiePayQrCodeInKiosk) {
        // don't await
        UI.showMenu(UI.embeddedCName);
        UI.showKioskOrderSuccess = true;
      }
    }

    // stop loading.
    setLoading(false);
  }

  const [submitOrder] = useMutation(createOrderV2, {
    onCompleted: afterSubmit,
    client: apolloClient,
  });

  if (!dispensary) {
    return (
      <ModalContainer ref={ref} width={850} {...containerProps}>
        {t('common.loading', 'Loading...')}
      </ModalContainer>
    );
  }

  async function handleSubmitOrder() {
    if (loading) {
      return;
    }

    if (!dispensary.kioskOrderingEnabled) {
      UI.showErnie(
        `Kiosk ordering is not currently enabled for ${dispensary.name}.
        Please contact dutchie support to enable this feature.`,
        `danger`
      );
      onClose();
      return;
    }

    if (Cart.isKioskOrder && Cart.paymentMethod === 'dutchiePay') {
      UI.updateShowDutchiePayQrCodeInKiosk(true);
    }

    setLoading(true);

    const data = formMethods.getValues();
    const { notes, destinationTerminal } = data;
    let { birth = '', firstName = '', lastName = '', phone, email } = data;

    if (isAnonymousCheckout) {
      Cart.setIsAnonymousKioskCheckout(true);
      birth = '';
      firstName = `Anonymous`;
      lastName = `Order`;
      phone = '';
      email = '';
    }

    const [birthMonth, birthDay, birthYear] = _.split(birth, '/');

    const directedOrders = dispensary?.orderTypesConfigV2.kiosk?.directedOrders;

    if (directedOrders && !_.isEmpty(kioskTerminals)) {
      if (!destinationTerminal) {
        UI.showErnie(`Please select a terminal for pickup`, `danger`);
        setLoading(false);
        return;
      }

      Cart.setDestinationTerminal(destinationTerminal);
    }

    Cart.updateGuestCustomer(`birthMonth`, birthMonth);
    Cart.updateGuestCustomer(`birthDay`, birthDay);
    Cart.updateGuestCustomer(`birthYear`, birthYear);
    Cart.updateGuestCustomer(`firstName`, firstName);
    Cart.updateGuestCustomer(`lastName`, lastName);
    Cart.updateGuestCustomer(`phone`, phone);
    Cart.updateGuestCustomer(`email`, email);
    Cart.setSpecialInstructions(notes);
    const orderTotal = (Cart?.costBreakdown?.total ?? 0).toFixed(2);

    // process submit action
    const result = await onClickSubmit(submitOrder, {
      Cart,
      UI,
      User,
      apolloClient,
      showErnie: UI.showErnie,
      Checkout: contextStore,
      ...(useAnonymousKioskCheckout ? { isAnonymousCheckout } : {}),
    });

    const cartEventTracking = getCartEventProperties(Cart, UI);

    if (result?.error && !_.isEmpty(result?.msg)) {
      const errorMsg = result?.msg;
      cartEventTracking.checkoutError = errorMsg;
      UI.showErnie(errorMsg, `danger`);
    }

    cartEventTracking.orderTotal = orderTotal;

    amplitude.log(AmplitudeEvents.checkout.placeOrder, {
      ...cartEventTracking,
      description: `User clicks "Place Order"`,
      category: AmplitudeCategory.checkout,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
      outOfStockItem: result?.outOfStockItem,
    });

    setLoading(false);
  }

  const onSubmitFormError = async (errors, _e) => {
    let errorMessages = [];
    _.forEach(_.keys(errors), (name) => {
      const error = errors[name];
      if (error.type === 'required') {
        if (checkoutErrors[name]?.missing) {
          errorMessages.push(checkoutErrors[name].missing);
        } else if (name === 'birth') {
          errorMessages.push(checkoutErrors.birthday.missing);
        }
      }
    });

    const errorMessageToDisplay =
      errorMessages.length > 0 ? _.head(errorMessages) : `Please fill out the missing information`;

    UI.showErnie(errorMessageToDisplay, `danger`);

    const cartEventTracking = getCartEventProperties(Cart, UI);

    amplitude.log(AmplitudeEvents.checkout.placeOrder, {
      ...cartEventTracking,
      checkoutError: errorMessages?.join(`, `),
      description: `User clicks "Place Order"`,
      category: AmplitudeCategory.checkout,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
    });

    errorMessages = [];
    setLoading(false);
  };

  const onCloseModal = () => {
    onClose();
    UI.updateShowDutchiePayQrCodeInKiosk(false);
  };

  const handleDutchiePayOrderSuccessClose = () => {
    onClose();
    Cart.clearOrder();
    formMethods.reset();
    UI.showMenu(UI.embeddedCName);
    UI.updateShowDutchiePayKioskOrderSuccess(false);
  };

  return (
    <ModalContainer ref={ref} width={850} {...containerProps} padding={0}>
      <ModalExitIcon onClick={onCloseModal} />
      <CheckoutProvider value={contextStore}>
        <Container>
          {showDutchiePayKioskOrderSuccess && <KioskOrderSuccessModal onClose={handleDutchiePayOrderSuccessClose} />}
          {showDutchiePayQrCodeInKiosk && <KioskQrCode />}
          {!showDutchiePayKioskOrderSuccess && !showDutchiePayQrCodeInKiosk && (
            <FormProvider {...formMethods}>
              <form onSubmit={(e) => e.preventDefault()}>
                <LeftArea>
                  <OrderInformation
                    dispensary={dispensary}
                    isAnonymousCheckout={isAnonymousCheckout}
                    setIsAnonymousCheckout={setIsAnonymousCheckout}
                  />
                  <DirectOrders dispensary={dispensary} />
                  <PaymentOptions dispensary={dispensary} />
                </LeftArea>
                <RightArea>
                  <OrderItems
                    dispensary={dispensary}
                    loading={loading}
                    onSubmitOrder={formMethods.handleSubmit(handleSubmitOrder, onSubmitFormError)}
                  />
                </RightArea>
              </form>
            </FormProvider>
          )}
        </Container>
      </CheckoutProvider>
    </ModalContainer>
  );
});
export default KioskCheckoutModal;

const Container = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: left;

  > form {
    position: relative;
    display: flex;
    flex-direction: row;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: 100vh;
    overflow-y: auto;
  }
`;

const Area = styled.div`
  padding-top: 31px;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const LeftArea = styled(Area)`
  width: 55%;
`;

const RightArea = styled(Area)`
  width: 45%;
  background-color: ${({ theme }) => theme.colors.grey[95]};
`;
