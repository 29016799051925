import { useMemo } from 'react';
import { ApolloError } from 'apollo-client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Product } from 'types/graphql-overrides';
import {
  GqlGetSponsoredProductsInput,
  useGetSponsoredProductsQuery,
  SponsoredProductsInventoryId,
} from 'types/graphql';

import { parseProduct } from 'src/utils/helpers/product';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { ADS_FETCH_POLICY } from 'src/utils/ads/helpers';

type PrependWithSponsoredProductsArgs = Omit<GqlGetSponsoredProductsInput, 'dispensaryId'> & {
  skip?: boolean;
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

type PrependWithSponsoredProducts = {
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

const getSponsoredProductsLimit = (inventoryId: string, flags: any): number => {
  const categoryPageLimit = flags['growth.ads.sponsored-products.increased-display-count'] ?? 1;
  const cartTopperLimit = flags['growth.ads.sponsored-products.cart-topper-increased-display-count'] ?? 1;
  const pdpSponsoredProductsLimit = flags['growth.ads.sponsored-products.pdp-increased-display-count'] ?? 1;

  if (inventoryId === SponsoredProductsInventoryId.cartTopperProductAuc) {
    return cartTopperLimit;
  }

  if (inventoryId === SponsoredProductsInventoryId.pdpProductAuc) {
    return pdpSponsoredProductsLimit;
  }

  return categoryPageLimit;
};

const shouldSkipQuery = (
  skip: boolean,
  dispensaryId: string | undefined,
  productsError: ApolloError | undefined,
  sponsoredProductsLimit: number
): boolean => skip || !dispensaryId || productsError instanceof Error || sponsoredProductsLimit === 0;

export function usePrependWithSponsoredProducts({
  skip = true,
  products,
  loading: productsLoading,
  error: productsError,
  limit,
  inventoryId,
  category,
  searchQuery,
}: PrependWithSponsoredProductsArgs): PrependWithSponsoredProducts {
  const { dispensary } = useDispensary();
  const dispensaryId = dispensary?.id;
  const safeCategory = category ?? undefined;
  const flags = useFlags();
  const sponsoredProductsLimit = getSponsoredProductsLimit(inventoryId, flags);
  const skipQuery = shouldSkipQuery(skip, dispensaryId, productsError, sponsoredProductsLimit);

  const { data, loading: sponsoredProductsLoading, error: sponsoredProductsError } = useGetSponsoredProductsQuery({
    fetchPolicy: ADS_FETCH_POLICY,
    skip: skipQuery,
    variables: {
      input: {
        limit,
        dispensaryId,
        inventoryId,
        category: safeCategory,
        searchQuery,
      },
    },
  });

  const sponsoredProductsData = data?.getSponsoredProducts;

  const serializedProducts = useMemo(() => {
    if (productsLoading || sponsoredProductsLoading || productsError) {
      return [];
    }

    if (!sponsoredProductsData?.length || sponsoredProductsError) {
      return products;
    }

    const sponsoredProducts = sponsoredProductsData.slice(0, sponsoredProductsLimit).map(parseProduct);

    const sponsoredProductsIds = new Set(sponsoredProducts.map((item) => item.id));

    return sponsoredProducts.concat(products.filter((item) => !sponsoredProductsIds.has(item.id)));
  }, [
    products,
    productsLoading,
    productsError,
    sponsoredProductsData,
    sponsoredProductsLoading,
    sponsoredProductsError,
    sponsoredProductsLimit,
  ]);

  return {
    products: serializedProducts,
    loading: productsLoading || sponsoredProductsLoading,
    error: productsError,
  };
}
