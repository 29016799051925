import React from 'react';
import { useObserver } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { observer } from 'mobx-react';

import useDispensaryPaymentMethods from 'shared/hooks/use-dispensary-payment-methods';
import { useDutchiePay } from 'src/payments/hooks';
import useStores from 'shared/hooks/use-stores';
import useUI from 'src/hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';

import DutchiePayIcon from 'shared/assets/dutchie-pay-icon';
import SecureLockIcon from 'shared/assets/secure-lock-icon';
import { editBankAccount } from 'src/payments/constants';
import { TruncateText, WhiteLabelLogo } from 'src/payments/components';
import { ModalNames, useModals } from 'components/modals';
import { InfoIconAlt } from 'assets/info-icon-alt';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import { useViewport } from 'contexts/viewport';
import { PurpleBoltIconSmall } from 'src/assets/purple-bolt-icon-small';

const DutchiePay = ({ bankInfo = true }) => {
  const { t } = useTranslation();
  const paymentOptions = useDispensaryPaymentMethods();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { width } = useViewport();
  const { Cart } = useStores();
  const dutchiePayPaymentOption = _.find(paymentOptions, { value: 'dutchiePay' });
  const { isEnrolledDutchiePay, bankAccounts, requiresAggregatorMigration, whiteLabelConfig } = useDutchiePay();
  const { isMobileEcommApp } = useUI();

  const { openModal } = useModals();
  const needsRelinkBankAccount = useObserver(() => DutchiePayEnrollment.needsRelinkBankAccount);

  const { openDutchiePay } = useOpenDutchiePay({
    cname: Cart.dispensary.cName,
    step: editBankAccount,
  });

  const xsDevice = width <= 320;

  const bankAccountData = bankAccounts[0];

  const onEditButtonClick = () => {
    if (isMobileEcommApp) {
      openModal(ModalNames.dutchiePayVisitOnDesktop);
    } else {
      DutchiePayEnrollment.editBankAccount();
      openDutchiePay();
    }
  };

  const onRelinkBankAccountClick = () => {
    openModal(ModalNames.dutchiePayRelinkBankAccount);
  };

  const showBankInfo = bankInfo && isEnrolledDutchiePay && !requiresAggregatorMigration;

  return (
    <DutchiePayWrapper>
      <IconContainer>
        {whiteLabelConfig.useWhiteLabel ? (
          <WhiteLabelLogo variant='dark' height='19' />
        ) : (
          <>
            {Cart.isKioskOrder && <PayByBank>{t('dutchiePay.payByBank', 'Pay by Bank')}</PayByBank>}
            {!Cart.isKioskOrder && <DutchiePayIcon height='19' width='98' />}
            <SubLabel>{dutchiePayPaymentOption?.sublabel}</SubLabel>
            {Cart.isKioskOrder && (
              <CheckoutFasterTag>
                <PurpleBoltIconSmall />
                Faster checkout
              </CheckoutFasterTag>
            )}
          </>
        )}
      </IconContainer>
      {showBankInfo && (
        <DutchiePayInfoWrapper>
          <SecureLockIcon />
          <DutchiePaySubTitle>
            <TextWrapper>
              <TruncateText maxWidth={100}>
                <DutchiePayBankName>{bankAccountData.bankName}</DutchiePayBankName>
              </TruncateText>
              <SpacedText>{t('common.accountEndingIn', 'ending in')}</SpacedText>
              <span>{bankAccountData.lastDigits}</span>
            </TextWrapper>
            {needsRelinkBankAccount ? (
              <IconWrapper onClick={onRelinkBankAccountClick}>
                <InfoIconAlt />
              </IconWrapper>
            ) : (
              <>
                <EditButton type='button' onClick={onEditButtonClick}>
                  {t('common.edit', 'Edit')}
                </EditButton>
              </>
            )}
          </DutchiePaySubTitle>
        </DutchiePayInfoWrapper>
      )}
      {requiresAggregatorMigration && (
        <DutchiePayInfoWrapper>
          <SecureLockIcon />
          <DutchiePaySubTitle>
            <TextWrapper>
              <TruncateText maxWidth={xsDevice ? 110 : 170}>
                {t('common.bankUnlinked', 'Relink with Plaid at checkout')}
              </TruncateText>
            </TextWrapper>
            <IconWrapper onClick={onRelinkBankAccountClick}>
              <InfoIconAlt />
            </IconWrapper>
          </DutchiePaySubTitle>
        </DutchiePayInfoWrapper>
      )}
    </DutchiePayWrapper>
  );
};
export default observer(DutchiePay);

const DutchiePayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubLabel = styled.span`
  ${({ theme: { colors, spaces } }) => css`
    margin-left: ${spaces[1]};
    font-size: 13px;
    color: ${colors.grey[30]};
  `}
`;

const DutchiePayInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 17px 1fr;
  align-items: center;
  padding: 7px 0 0 0;
  color: ${({ theme }) => theme.colors.grey[70]};
`;

const IconWrapper = styled.button`
  ${({ theme: { spaces } }) => css`
    height: ${spaces[4]};
    margin-left: ${spaces[2]};
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
  `}
`;

const DutchiePayBankName = styled.span`
  text-transform: capitalize;
`;

const DutchiePaySubTitle = styled.p`
  ${({ theme: { colors, spaces } }) => css`
    padding: ${spaces[1]} 0 0 ${spaces[1]};
    font-size: 13px;
    line-height: 1;
    color: ${colors.grey[45]};
    display: flex;
    align-items: center;
  `}
`;

const PayByBank = styled.p`
  ${({ theme: { colors, spaces } }) => css`
    font-size: 14px;
    color: ${colors.grey[45]};
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0 ${spaces[1]} 0 0;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  height: 13px;
`;

export const SpacedText = styled.span`
  white-space: nowrap;

  &::before,
  &::after {
    content: ' ';
    white-space: pre;
  }
`;

export const Text = styled.span`
  white-space: nowrap;
  &::before,
  &::after {
    white-space: pre;
  }
`;

const EditButton = styled.button`
  color: ${({ theme }) => theme.colors.blue[55]};
  border: 0;
  background: transparent;
  cursor: pointer;
  margin: 0 0 0 4px;
  padding: 0;
  height: 13px;
`;

const CheckoutFasterTag = styled.div`
  ${({ theme: { spaces } }) => css`
    align-items: center;
    background: #eadaf2;
    border-radius: 14px;
    color: #3c0059;
    display: flex;
    font-size: 10px;
    font-weight: 600;
    gap: ${spaces[1]};
    letter-spacing: 0.5px;
    line-height: 10.94px;
    padding: ${spaces[1]} ${spaces[2]};
  `}
`;
