/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

import { AnalyticsContext, AnalyticsProduct } from 'utils/analytics/events';
import {
  CartContext,
  ContextCache,
  DispensaryContext,
  EcommGTMContext,
  GTMCartItem,
  UserContext,
} from '../internal-gtm-tracker.types';

type EcommerceGTMMetaData = {
  context: AnalyticsContext;
  contextCache: ContextCache;
  payload?: {
    cartId: string;
    products: AnalyticsProduct[];
    dispensaryId: string;
    dispensaryName: string;
  };
};

export const ecommerceGTMMetaData = ({ context, contextCache, payload }: EcommerceGTMMetaData): EcommGTMContext => ({
  affiliation: context.activeDispensary?.name ?? contextCache.activeDispensary?.name ?? payload?.dispensaryName,
  currency: 'USD',
  value: context.activeCart?.subtotal?.toFixed(2) ?? contextCache.activeCart?.subtotal?.toFixed(2),
});

export const cartItemContext = (
  context: AnalyticsContext,
  contextCache: ContextCache,
  payload?: { cartId: string; products: AnalyticsProduct[]; dispensaryId: string; dispensaryName: string }
): EcommGTMContext => ({
  affiliation: context.activeDispensary?.name ?? contextCache.activeDispensary?.name ?? payload?.dispensaryName,
});

export const cartItemToGTMCartItem = (item): GTMCartItem => {
  // Including these as custom parameters per https://developers.google.com/analytics/devguides/collection/ga4/item-scoped-ecommerce
  const provider = item.provider ?? item.product.provider;
  const placement = item.placement ?? null;

  const gtmCartItem: GTMCartItem = {
    item_id: item.product.id,
    item_name: item.product.Name,
    item_category: item.product.type ?? item.product.category,
    item_category_2: item.product.subcategory,
    item_variant: item.option,
    item_additional_variant: item.additionalOption,
    item_brand: item.product.brand?.name ?? item.product.brandName,
    quantity: item.quantity,
    price: item.basePrice ?? item.price ?? item.product?.Prices?.[0],
    item_list_name: item.trackerSource,
  };

  if (provider) {
    gtmCartItem.provider = provider;
  }

  if (placement) {
    gtmCartItem.placement = placement;
  }

  return gtmCartItem;
};

export const getDispensaryContext = (context: AnalyticsContext, cache: ContextCache): DispensaryContext => ({
  dispensary_id: context.activeDispensary?.id ?? cache.activeDispensary?.id ?? undefined,
  dispensary_name: context.activeDispensary?.name ?? cache.activeDispensary?.name ?? undefined,
  dutchie_pay_enabled:
    context.activeDispensary?.isDutchiePayEnabledForDispo ?? cache.activeDispensary?.isDutchiePayEnabledForDispo,
});

export const getUserContext = (context: AnalyticsContext, cache: ContextCache): UserContext => ({
  customer_id: context.activeUser?.id ?? cache.activeUser?.id,
  logged_in: context.activeUser?.isLoggedIn ?? cache.activeUser?.isLoggedIn,
  user_enrolled_dutchie_pay: context.activeUser?.isEnrolledDutchiePay ?? cache.activeUser?.isEnrolledDutchiePay,
});

export const getCartContext = (context: AnalyticsContext, cache: ContextCache): CartContext => ({
  checkout_token: context.activeCart?.checkoutToken ?? cache.activeCart?.checkoutToken,
});
