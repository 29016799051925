/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

import * as yup from 'yup';

// prettier-ignore
const baseEventSchema = yup.object({
  dispensary_id: yup
    .string()
    .required(),
  menu_variant: yup
    .string()
    .required(),
});

// prettier-ignore
const itemSchema = yup.object({
  id: yup
    .string()
    .required(),
  library_product_id: yup
    .string()
    .nullable(),
  library_product_score: yup
    .number()
    .nullable(),
  brand_id: yup
    .string()
    .nullable(),
  parent_brand_id: yup
    .string()
    .nullable(),
})

// prettier-ignore
export const cartItemSchema = itemSchema.shape({
  price: yup
    .number()
    .required(),
  quantity: yup
    .number()
    .required(),
  option: yup
    .string()
    .nullable(),
});

// prettier-ignore
export const placedOrderCartItemSchema = cartItemSchema.shape({
  base_price: yup
    .number()
    .required(),
  discounted_base_price: yup
    .number()
    .nullable(),
  total_price: yup
    .number()
    .required(),
});

const addedProductToCartSchema = baseEventSchema.shape({
  item: cartItemSchema,
});

const removedProductFromCartSchema = baseEventSchema.shape({
  item: cartItemSchema,
});

// prettier-ignore
const placedOrderSchema = baseEventSchema.shape({
  subtotal: yup
    .number()
    .required(),
  total: yup
    .number()
    .required(),
  discount: yup
    .number()
    .required(),
  final_total: yup
    .number()
    .required(),
  items: yup
    .array()
    .of(placedOrderCartItemSchema)
    .required(),
  order_id: yup
    .string()
    .required()
});

// prettier-ignore
const searchResultsSchema = baseEventSchema.shape({
  query: yup
    .string()
    .required(),
});

const viewedPageSchema = baseEventSchema;

// prettier-ignore
const viewedProductSchema = baseEventSchema.shape({
  item: itemSchema,
});

const cartViewedSchema = baseEventSchema;

// prettier-ignore
const productClickedSchema = baseEventSchema.shape({
  item: itemSchema,
  placement: yup.
    string(),
  provider: yup.
    string(),
});

// prettier-ignore
const trackerItemSchema = yup.object({
  id: yup
    .string()
    .nullable(),
  library_product_id: yup
    .string()
    .nullable(),
  brand_id: yup
    .string()
    .nullable(),
  parent_brand_id: yup
    .string()
    .nullable(),
})

// prettier-ignore
const productTrackerSchema = baseEventSchema.shape({
  tracking_url: yup
    .string()
    .required(),
  item: trackerItemSchema,
})

// prettier-ignore
const bannerTrackerSchema = baseEventSchema.shape({
  tracking_url: yup
    .string()
    .required(),
  items: yup
    .array()
    .of(trackerItemSchema),
})

const eventsSchema = {
  addedProductToCart: addedProductToCartSchema,
  cartItem: cartItemSchema,
  placedOrder: placedOrderSchema,
  searchResults: searchResultsSchema,
  productClicked: productClickedSchema,
  sponsoredBannerImpression: bannerTrackerSchema,
  sponsoredBannerClicked: bannerTrackerSchema,
  sponsoredProductClicked: productTrackerSchema,
  sponsoredProductImpression: productTrackerSchema,
  viewedPage: viewedPageSchema,
  cartViewed: cartViewedSchema,
  viewedProduct: viewedProductSchema,
  removedProductFromCart: removedProductFromCartSchema,
};

export type Data = {
  type: keyof typeof eventsSchema;
  payload: Record<string, unknown>;
};

export function validateSchema(data: Data): void {
  eventsSchema[data.type].validateSync(data.payload);
}
