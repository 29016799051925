import React, { memo } from 'react';
import styled from 'styled-components';
import Truncate from 'react-truncate';
import { observer } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import usePaths from 'hooks/use-paths';
import useUI from 'hooks/use-ui';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import { tracker } from 'utils/analytics';

import { getCategoryForProduct } from 'shared/helpers/products';
import { EmbeddedExternalLink, Link } from 'components/core';
import { breakpoints } from 'components/carousel-slider/helpers';
import { Typography } from 'src/typography';
import { Product } from 'types/graphql-overrides';
import { ProductPlacement } from 'src/types';

import { SponsoredTag } from 'utils/ads/ui';
import { Price } from './price';
import { StrainDetails } from './strain-details';
import { Container, Content, Details, Name, ProductImage, StyledLink } from './product-card.components';
import { ProductCardProduct } from './product-card.types';
import { CARD_SIZES, Dimensions } from './product-card.constants';
import { QuickAddButton } from './quick-add-button';
import { getBrandName } from './product-card.utils';
import { AddToCartButton } from './add-to-cart-button';

export const TEST_ID_CAROUSEL_PRODUCT_CARD = 'carousel-product-card-anchor';

export type CarouselProductCardProps = {
  dimensions?: Dimensions;
  product: ProductCardProduct;
  productIndex: number;
  onClick?: () => void;
  onQuickAddClick?: () => void;
  trackerSource?: string | null;
  isEmbeddedCarousel?: boolean;
  placement?: ProductPlacement;
  hideSponsoredTags?: boolean;
};
export const CarouselProductCard = memo(
  ({
    dimensions = CARD_SIZES.default,
    product: _product,
    productIndex,
    onClick,
    onQuickAddClick,
    trackerSource = null,
    isEmbeddedCarousel = false,
    placement,
    hideSponsoredTags = true,
  }: CarouselProductCardProps): JSX.Element | null => {
    // FIXME: remove casting after /shop codebase removed
    const product = _product as Product;

    const flags = useFlags();
    const isAddToCartCTAEnabled = flags['growth.ecomm.add-to-cart-cta.experiment'] ?? false;

    const brandName = getBrandName(product);

    const handleClick = (): void => {
      onClick?.();
      tracker.productClicked({ product, position: productIndex, trackerSource, placement });
    };

    const handleClickQuickAddButton = (): void => {
      onQuickAddClick?.();
      tracker.productClicked({ product, position: productIndex, trackerSource, placement });
    };

    const comingSoonProductsFlagEnabled = useDispensaryFlag(
      `rollout.coming-soon-products`,
      'DispensaryID' in product && product.DispensaryID
    );

    const isComingSoon = !!(comingSoonProductsFlagEnabled && 'comingSoon' in product && product.comingSoon);
    const displayQuickAddButton =
      !isAddToCartCTAEnabled && !isComingSoon && ((!isEmbeddedCarousel && onQuickAddClick) || isEmbeddedCarousel);
    const displayAddToCartButton =
      isAddToCartCTAEnabled && !isComingSoon && ((!isEmbeddedCarousel && onQuickAddClick) || isEmbeddedCarousel);
    const isSponsored = !!product.adTrackers;

    return (
      <StyledContainer dimensions={dimensions} data-testid='carousel-product-card'>
        <Content>
          <LinkComponent product={product} onClick={handleClick}>
            <ProductImage
              dimensions={dimensions}
              product={product}
              width={dimensions.desktop}
              height={dimensions.desktop}
            />
            {isComingSoon}
            <Details dimensions={dimensions}>
              {!hideSponsoredTags && <SponsoredTag isSponsored={isSponsored} />}
              <Price product={product} isComingSoon={isComingSoon} />
              <StyledName>
                <Truncate lines={2}>
                  <Typography.Title tag='span' size='small'>
                    {product.name}
                  </Typography.Title>
                </Truncate>
              </StyledName>
              {brandName.length > 0 && (
                <Brand tag='span' size='xsmall'>
                  {brandName}
                </Brand>
              )}
              <StrainDetails product={product} />
            </Details>
            {displayAddToCartButton && (
              <AddToCartButton
                buttonCopy={isEmbeddedCarousel ? 'View Item' : null}
                isEmbeddedCarousel={isEmbeddedCarousel}
                onClick={handleClickQuickAddButton}
                product={product}
              />
            )}
          </LinkComponent>
          {displayQuickAddButton && (
            <QuickAddButton
              onClick={isEmbeddedCarousel ? handleClick : handleClickQuickAddButton}
              productName={product.name}
            />
          )}
        </Content>
      </StyledContainer>
    );
  }
);

type LinkComponentProps = {
  children: React.ReactNode;
  product: ProductCardProduct;
  onClick?: () => void;
};
const LinkComponent = observer(
  ({ children, product, onClick }: LinkComponentProps): JSX.Element => {
    const { isEmbeddedCarousel } = useUI();
    const { href, route } = usePaths({ category: getCategoryForProduct(product), product });

    if (isEmbeddedCarousel) {
      return (
        <EmbeddedExternalLink href={href}>
          <StyledLink href={href} onClick={onClick}>
            {children}
          </StyledLink>
        </EmbeddedExternalLink>
      );
    }

    return (
      <Link href={href} route={route}>
        <StyledLink href={href} onClick={onClick} data-testid={TEST_ID_CAROUSEL_PRODUCT_CARD}>
          {children}
        </StyledLink>
      </Link>
    );
  }
);

const StyledContainer = styled(Container)`
  padding: 0;
  min-width: ${({ dimensions }) => dimensions.desktop}px;
  max-width: ${({ dimensions }) => dimensions.desktop}px;

  @media (min-width: ${breakpoints.mobile}px) {
    padding: ${({ dimensions }) => (dimensions.padding ? `${String(dimensions.padding)}px` : `0`)};
    max-width: 214px;
  }
  @media (max-width: ${breakpoints.mobile}px) {
    max-width: ${({ dimensions }) => dimensions.mobile}px;
    padding: 16px;
  }
`;

const StyledName = styled(Name)`
  @media (min-width: ${breakpoints.largeTablet}px) {
    font-size: ${({ cardSize }) => (cardSize === `large` ? `15px` : `14px`)};
  }
`;

const Brand = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;
